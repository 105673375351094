import { Routes } from "@angular/router";

export enum RouteKey {
  Root = "Root",
}

export const routes = {
  Root: "behold-din-livsstil",
};

export const needsLazyRoutes: Routes = [
  {
    path: "",
    loadComponent: () => import("./pages/needs-page/needs-page.component").then((c) => c.NeedsPageComponent),
    data: {
      title: "pageTitle.needs",
      breadcrumb: {
        hideAll: true,
      },
    },
  },
];
